import { useTranslation } from "react-i18next";

const BYH = () => {
  const { t } = useTranslation();
  return (
    <div className="byh">
      <div className="container">
        <div className="byh-inner">
          <div className="row">
            <div className="img-title-wrapper">
              <img src="/images/byh/betyourhand.png" alt="title image" />
            </div>
          </div>
          <div className="row">
            <h3>{t("betYourHand")}</h3>
            <p
              dangerouslySetInnerHTML={{ __html: t("betYourHandDescription") }}
            />
          </div>
          <div className="row">
            <p dangerouslySetInnerHTML={{ __html: t("joinAction") }} />
          </div>
          <div className="row">
            <h4>{t("whatIsIt")}</h4>
            <p
              dangerouslySetInnerHTML={{ __html: t("whatIsItDescriptionByh") }}
            />
          </div>
          <div className="row">
            <h4>{t("howItWorks")}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t("howItWorksDescriptionByh"),
              }}
            />
          </div>
          <div className="row">
            <h4>{t("winningCombinationsAndPrizes")}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t("winningCombinationsAndPrizesDescription"),
              }}
            />
          </div>
          <div className="row">
            <div className="combination-wrapper">
              <div className="item">
                <div className="combination-header">
                  <h3>{t("texasHoldem")}</h3>
                  <div className="header_texts">
                    <span>{t("compination")}</span>
                    <span>{t("combinationDescription")}</span>
                  </div>
                </div>
                <div className="combination-body">
                  <div className="combination-item">
                    <div className="left">
                      <i>QQ Royal Flush</i>
                      <i className="small">
                        (A234567 in any suit using both of your hole cards and 5
                        cards on the board)
                      </i>
                    </div>
                    <h4>250,000</h4>
                    <h4>1.000,000</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Super Royal Flush</i>
                      <i className="small">
                        (AKQJ10 in 1 suit, using both of your hole cards and the
                        first 3 cards on the board)
                      </i>
                    </div>
                    <h4>30,000</h4>
                    <h4>50,000</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Royal Flush</i>
                      <i className="small">(AKQJ10 in 1 suit)</i>
                    </div>
                    <h4>10,000</h4>
                    <h4>10,000</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Straight Flush</i>
                      <i className="small">(Any straight in 1 suit)</i>
                    </div>
                    <h4>1,500</h4>
                    <h4>1,500</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Four of a Kind</i>
                      <i className="small">(Any 4 of a kind)</i>
                    </div>
                    <h4>200</h4>
                    <h4>200</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Full House Aces</i>
                      <i className="small">(3 Aces + any pair)</i>
                    </div>
                    <h4>100</h4>
                    <h4>100</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Ace High Flush</i>
                      <i className="small">
                        (Ace + Any 4 cards in the same suit)
                      </i>
                    </div>
                    <h4>10</h4>
                    <h4>10</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Ace High Straight</i>
                      <i className="small">(Straight Ace high AKQJ10)</i>
                    </div>
                    <h4>10</h4>
                    <h4>10</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Three of a Kind Aces</i>
                      <i className="small">(3 Aces)</i>
                    </div>
                    <h4>10</h4>
                    <h4>10</h4>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="combination-header">
                  <h3>{t("omaha")}</h3>
                  <div className="header_texts">
                    <span>{t("compination")}</span>
                    <span>{t("combinationDescription")}</span>
                  </div>
                </div>
                <div className="combination-body">
                  <div className="combination-item">
                    <div className="left">
                      <i>QQ Royal Flush</i>
                      <i className="small">A234567 in any suit)</i>
                    </div>
                    <h4>100,000</h4>
                    <h4>250,000</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Super Royal Flush</i>
                      <i className="small">
                        AKQJT in 1 suit, using 2 any hole cards and the first 3
                        cards on the board)
                      </i>
                    </div>
                    <h4>20,000</h4>
                    <h4>25,000</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Royal Flush</i>
                      <i className="small">(AKQJ10 in 1 suit)</i>
                    </div>
                    <h4>2,500</h4>
                    <h4>2,500</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Straight Flush</i>
                      <i className="small">(Any straight in 1 suit)</i>
                    </div>
                    <h4>250</h4>
                    <h4>250</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Four of a Kind</i>
                      <i className="small">(Any 4 of a kind)</i>
                    </div>
                    <h4>50</h4>
                    <h4>50</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Full House Aces</i>
                      <i className="small">(3 Aces + any pair)</i>
                    </div>
                    <h4>25</h4>
                    <h4>25</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Ace High Straight</i>
                      <i className="small">(Straight Ace high AKQJ10)</i>
                    </div>
                    <h4>10</h4>
                    <h4>10</h4>
                  </div>
                  <div className="combination-item">
                    <div className="left">
                      <i>Ace High Flush</i>
                      <i className="small">
                        (Ace + Any 4 cards in the same suit)
                      </i>
                    </div>
                    <h4>5</h4>
                    <h4>5</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <h4>{t("costToParticipate")}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t("costToParticipateDescription"),
              }}
            />
          </div>
          <div className="row">
            <div className="cost-participate-wrapper">
              <div className="item">
                <div className="cost-participate-header">
                  <span>{t("tableLimit")}</span>
                  <div>
                    <span>{t("single")}</span>
                    <span>{t("double")}</span>
                    <span>{t("max")}</span>
                  </div>
                </div>
                <div className="cost-participate-body">
                  <div className="cost-participate-item">
                    <i>
                      <span>0,05/0,10;0,10/0,25</span>
                    </i>
                    <div>
                      <i>
                        <strong>0,01</strong>
                      </i>
                      <i>
                        <strong>0,02</strong>
                      </i>
                      <i>
                        <strong className="red">0,1</strong>
                      </i>
                    </div>
                  </div>
                  <div className="cost-participate-item">
                    <i>
                      <span>0,25/0,50</span>
                    </i>
                    <div>
                      <i>
                        <strong>0,02</strong>
                      </i>
                      <i>
                        <strong>0,04</strong>
                      </i>
                      <i>
                        <strong className="red">0,20</strong>
                      </i>
                    </div>
                  </div>
                  <div className="cost-participate-item">
                    <i>
                      <span>0,5/1,00</span>
                    </i>
                    <div>
                      <i>
                        <strong>0,05</strong>
                      </i>
                      <i>
                        <strong>0,10</strong>
                      </i>
                      <i>
                        <strong className="red">0,50</strong>
                      </i>
                    </div>
                  </div>
                  <div className="cost-participate-item">
                    <i>
                      <span>1,00/2,00</span>
                    </i>
                    <div>
                      <i>
                        <strong>0,10</strong>
                      </i>
                      <i>
                        <strong>0,20</strong>
                      </i>
                      <i>
                        <strong className="red">1,00</strong>
                      </i>
                    </div>
                  </div>
                  <div className="cost-participate-item">
                    <i>
                      <span>1,50/2,00</span>
                    </i>
                    <div>
                      <i>
                        <strong>0,15</strong>
                      </i>
                      <i>
                        <strong>0,30</strong>
                      </i>
                      <i>
                        <strong className="red">1,50</strong>
                      </i>
                    </div>
                  </div>
                  <div className="cost-participate-item">
                    <i>
                      <span>2,50/5,00 and above</span>
                    </i>
                    <div>
                      <i>
                        <strong>0,25</strong>
                      </i>
                      <i>
                        <strong>0,50</strong>
                      </i>
                      <i>
                        <strong className="red">2,50</strong>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="rules-wrapper">
              <h3>{t("rules")}</h3>
              <ul>
                {Array.from({ length: 6 }, (_, k) => k).map((index) => {
                  return <li dangerouslySetInnerHTML={{ __html: t(`rulesConfig.${index}`) }} key={index} />;
                })}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="terms-conditions-wrapper">
              <h3>{t("termsConditions")}</h3>
              <ul>
                {Array.from({ length: 4 }, (_, k) => k).map((index) => {
                  return (
                    <li key={index}>
                      {t(`byhTermsConditionsConfig.${index}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BYH;
