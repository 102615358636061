import { useCallback } from 'react';
import { map } from 'ramda';
import { useTranslation } from 'react-i18next';
import languageConfig from '../../configs/language';
import Dropdown from '../Dropdown';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const onChange = useCallback(value => {
    i18n.changeLanguage(value);
  }, [i18n]);

  const getLanguages = map((lang) => {
    return ({ name: lang, value: lang, onClick: onChange });
  });

  return (
    <div className="language-selecor">
      <Dropdown
        options={getLanguages(languageConfig.lng)}
      />
    </div>
  );
};

export default LanguageSelector;
