import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const Dropdown = ({ options }) => {
  const { t } = useTranslation();
  const [option, setOption] = useState(options[0]);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setOpen);


  useEffect(() => {
    if (!window.localStorage.getItem("langDaddy")) {
      window.localStorage.setItem("langDaddy", JSON.stringify(options[0]));
    }
  }, []);

  useEffect(() => {
    const option = JSON.parse(window.localStorage.getItem("langDaddy"));
    if (option) {
      setOption(option);
      options.find(o => o.value === option.value).onClick(option.value);
    }
  }, []);

  const onClickHandler = (e, option) => {
    option.onClick(e.currentTarget.dataset.value);
    console.log(option);
    setOption(option);
    setOpen(false);
    window.localStorage.setItem("langDaddy", JSON.stringify(option));
  };

  return (
    <div className={`dropdown ${open ? "open" : ""}`} ref={wrapperRef}>
      <div className="header" onClick={() => setOpen((state) => !state)}>
        <span className={`drop-item ${open ? "active" : ""}`}>
          {t(option.name)}
        </span>
        <i className="icon-angle-down" />
      </div>
      <div className="body">
        {options
          .filter((item) => item.name !== option.name)
          .map((option, idx) => (
            <div
              data-value={option.value}
              key={idx}
              onClick={(e) => onClickHandler(e, option)}
            >
              <span className="drop-item">{t(option.name)}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dropdown;
