import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';

const Welcome = ({
  onScrollRef,
  isClubGames,
  bgcTransparent,
  withoutPlatform,
  withoutIntroImage,
  contactUsButtonPrimary,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const path = pathname === "/" ? '/home' : pathname;

  return (
    <div
      className={`welcome ${path.slice(1)} ${isClubGames ? "club-games" : ""} ${isMobile ? 'mobile' : ''}`}
      style={{
        background: bgcTransparent || isMobile
          ? "transparent"
          : `url('/images${path}/welcome/welcome-background.jpg') no-repeat center/cover`,
      }}
      >
      {isMobile && (
        <div className="welcome-image-wrapper">
          <img src={`/images${path}/welcome/welcome-background-mobile.jpg`} alt="welcome" />
        </div>
      )}
      <div className="container">
        <div className="welcome-inner">
          {!withoutIntroImage && !isMobile && (
            <div className="left">
              <img src={`/images${path}/welcome/welcome.png`} alt="welcome" />
            </div>
          )}
          <div
            className="right"
            style={{
              marginTop: isClubGames ? "auto" : "0",
            }}
          >
            <h2>{t(`${path.slice(1)}Config.title`)}</h2>
            <h3>{t(`${path.slice(1)}Config.description`)}</h3>
            <div className="buttons-wrapper">
              {!withoutPlatform && (
                <button onClick={onScrollRef} className="btn primary">
                  {t(`${path.slice(1)}Config.platformButton`)}
                </button>
              )}
              <a href="mailto:hello@gamedaddy.com">
                <button className={`btn ${contactUsButtonPrimary ? 'primary' : ''}`}>{t("contactUs")}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
