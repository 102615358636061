import { useCallback, useEffect, useRef } from "react";
import { debounce } from "../utils/debounce";

export const useDebouncedCallback = (callback, delay) => {
  const debouncedCallback = useRef(debounce(callback, delay));
  useEffect(() => {
    debouncedCallback.current = debounce(callback, delay);
  }, [callback, delay]);
  return useCallback(
    (...args) => debouncedCallback.current(...args),
    [debouncedCallback]
  );
};
