import BusinessModels from "../../components/BusinessModels";
import Products from "../../components/Products";
import Welcome from "../../components/Welcome";
import { isMobile } from "react-device-detect";

const Home = () => {
  return (
    <div className={`home ${isMobile ? 'mobile' : ''}`}>
      <Welcome withoutPlatform withoutIntroImage contactUsButtonPrimary />
      <Products />
      <BusinessModels />
    </div>
  );
};

export default Home;
