import Welcome from "../../components/Welcome";

const MatchPoker = () => {
  return (
    <>
      <Welcome withoutPlatform />
    </>
  );
};

export default MatchPoker;
