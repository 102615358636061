import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { navigationConfig } from "./config";

const NavigationMobile = ({ setOpen }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setOpen);
  return (
    <div className="navigation-mobile" ref={wrapperRef}>
      <div className="navigation-list">
        {navigationConfig.length > 0 &&
          navigationConfig.map((item) => (
            <NavLink
              exact
              activeClassName="active"
              key={item.name}
              to={item.path}
              onClick={() => setOpen(false)}
            >
              {t(item.name)}
            </NavLink>
          ))}
      </div>
    </div>
  );
};

export default NavigationMobile;
