import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { featuresConfig } from "./config";

const Features = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onRouteTo = (to) => history.push(to);

  return (
    <div
      className={`features ${isMobile ? 'mobile' : ''}`}
      style={{
        background:
          "url('/images/poker/welcome/welcome-background.jpg') no-repeat center/cover",
      }}
    >
      <div className="container">
        <div className="features-inner">
          <div className="left">
            <h3>{t(featuresConfig.title)}</h3>
            <div className="cards-wrapper">
              <div className="card large right betYourHand">
                <div className="info-title-wrapper">
                  <h4>{t("featuresConfig.betYourHand.title")}</h4>
                  <i className="icon-info" onClick={onRouteTo.bind(null, '/networktools/byh')} />
                </div>
                <p>{t("featuresConfig.betYourHand.description")}</p>
                
              </div>
              <div className="cards">
                <div className="card small colorfulThemes">
                  <h4>{t("featuresConfig.colorfulThemes.title")}</h4>
                  <p>{t("featuresConfig.colorfulThemes.description")}</p>
                </div>
                <div className="card small">
                  <h4>{t("featuresConfig.straddleMultiple.title")}</h4>
                  <p>{t("featuresConfig.straddleMultiple.description")}</p>
                </div>
              </div>
              <div className="card medium right insurance">
                <div className="info-title-wrapper">
                  <h4>{t("featuresConfig.insurance.title")}</h4>
                  <i className="icon-info" onClick={onRouteTo.bind(null, '/networktools/insurance')} />
                </div>
                <p>{t("featuresConfig.insurance.description")}</p>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="cards-wrapper">
              <div className="card small">
                <h4>{t("featuresConfig.runMultipleTimes.title")}</h4>
                <p>{t("featuresConfig.runMultipleTimes.description")}</p>
              </div>
              <div className="card large callTime">
                <h4>{t("featuresConfig.callTime.title")}</h4>
                <p>{t("featuresConfig.callTime.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
