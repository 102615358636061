import Home from "../pages/Home";
import Poker from "../pages/Poker";
import ClubGames from "../pages/ClubGames";
import GameBox from "../pages/GameBox";
import GamingPlatform from "../pages/GamingPlatform";
import MatchPoker from "../pages/MatchPoker";
import Insurance from "../pages/Insurance";
import BYH from "../pages/BYH";

export const routes = [
  { path: "/", component: Home },
  { path: "/poker", component: Poker },
  // { path: "/clubGames", component: ClubGames },
  { path: "/gamingPLatform", component: GamingPlatform },
  { path: "/gameBox", component: GameBox },
  { path: "/matchPoker", component: MatchPoker },
  { path: "/networktools/insurance", component: Insurance },
  { path: "/networktools/byh", component: BYH },
];
