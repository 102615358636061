import { useEffect } from "react";
import { useDebouncedCallback } from "./useDebouncedCallback";

export const useResizeHandler = (callback, delay) => {
  const resizeCallback = useDebouncedCallback(callback, delay);
  useEffect(() => {
    window.addEventListener("resize", resizeCallback);
    return () => {
      window.removeEventListener("resize", resizeCallback);
    };
  }, [resizeCallback]);
};
