import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { WindowSizeContext } from "../../App";

const Platforms = ({ node, data }) => {
  const { t } = useTranslation();
  const { width } = useContext(WindowSizeContext);
  return (
    <div className={`platforms ${isMobile ? "mobile" : ""}`} ref={node}>
      <div className="container">
        <div className="platforms-inner">
          <h3>{t("platforms")}</h3>
          {!isMobile && (
            <div className="platforms-wrapper">
              {data.map((item, index) => (
                <div key={index} className="platform">
                  <div className="platform-icon-text-wrapper">
                    <div className="platform-icon-wrapper">
                      <div className="platform-icon">
                        <i className={`icon-${item.icon}`} />
                      </div>
                    </div>
                    <div className="texts-wrapper">
                      <h3>{t(item.title)}</h3>
                      <p>{t(item.decription)}</p>
                    </div>
                  </div>
                  <div className="buttons-wrapper">
                    {item.buttons.map((button, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        href={button?.link || "#"}
                        target="_blank"
                      >
                        <button disabled={!button.link} className="btn primary">
                         {button?.iocn &&  <i className={`icon-${button.icon}`} />}
                          <span>{t(button.name, { version: index + 1 })}</span>
                        </button>
                      </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          {isMobile && (
            <Swiper
              slidesPerView={width < 668 ? 1 : 2}
              spaceBetween={12}
              centeredSlides={true}
              initialSlide={1}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div key={index} className="platform">
                    <div className="platform-icon-text-wrapper">
                      <div className="platform-icon-wrapper">
                        <div className="platform-icon">
                          <i className={`icon-${item.icon}`} />
                        </div>
                      </div>
                      <div className="texts-wrapper">
                        <h3>{t(item.title)}</h3>
                        <p>{t(item.decription)}</p>
                      </div>
                    </div>
                    <div className="buttons-wrapper">
                      {item.buttons.map((button, index) => (
                        <a
                          key={index}
                          rel="noreferrer"
                          href={button?.link || "#"}
                          target="_blank"
                        >
                          <button
                            disabled={!button.link}
                            className="btn primary"
                          >
                            {button?.icon && <i className={`icon-${button.icon}`} />}
                            <span>
                              {t(button.name, { version: index + 1 })}
                            </span>
                          </button>
                        </a>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Platforms;
