import { useState, Suspense, createContext } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Routes from "./components/Routes";
import { useResizeHandler } from "./hooks/useResizeHandler";
import "./i18n";

export const WindowSizeContext = createContext();

const WindowSize = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  
  useResizeHandler(handleResize, 500);
  
  return (
    <WindowSizeContext.Provider value={{ width, height }}>
      {children}
    </WindowSizeContext.Provider>
  );
};

const App = () => {
  const { pathname } = useLocation();
  const path = pathname === '/' ? 'home' : pathname.slice(1);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WindowSize>
        <div className={`App ${path}`}>
          <Header />
          <Routes />
          <Footer />
        </div>
      </WindowSize>
    </Suspense>
  );
};

export default App;
