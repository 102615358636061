import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WindowSizeContext } from "../../App";
import LanguageSelector from "../LanguageSelector";
import Logo from "../Logo";
import Navigation from "../Navigation";
import NavigationMobile from "../Navigation/NavigationMobile";

const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const { width } = useContext(WindowSizeContext);

  useEffect(() => {
    if (isOpen && width > 997) setOpen(false);
  }, [isOpen, width]);

  return (
    <div className="header">
      <div className="container">
        <div className="header-inner">
          <Logo />
          {width > 997 ? (
            <Navigation />
          ) : isOpen ? (
            <NavigationMobile setOpen={setOpen} />
          ) : null}
          <div
            className="right"
            style={{
              justifyContent: width > 997 ? "flex-end" : "center",
            }}
          >
            <a href="mailto:hello@gamedaddy.com"><button className="btn primary">{t("contactUs")}</button></a>
            <LanguageSelector />
          </div>
          {width <= 997 && (
            <i
              className="icon-menu-closed"
              onClick={() => setOpen((prev) => !prev)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
