import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { navigationConfig } from "./config";

const Navigation = () => {
  const { t } = useTranslation();
  return (
    <div className="navigation">
      <div className="navigation-list">
        {navigationConfig.length > 0 &&
          navigationConfig.map((item) => (
            <NavLink exact activeClassName="active" key={item.name} to={item.path}>
                {t(item.name)}
            </NavLink>
          ))}
      </div>
    </div>
  );
};

export default Navigation;
