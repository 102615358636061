import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const products = [
  { name: "poker", link: "/poker" },
  // { name: "poker-club", link: "/clubGames" },
  { name: "gaming-platform", link: "/gamingPlatform" },
];

const Products = () => {
  const { t } = useTranslation();
  const { isLandscape } = useMobileOrientation();

  return (
    <div className={`products ${isMobile ? "mobile" : ""}`}>
      <div className="container">
        <div className="products-inner">
          <h3>{t("products")}</h3>
          {(!isMobile || isLandscape) && (
            <div className="product-images">
              {products.map(({ name, link }) => (
                <Link to={link} key={name}>
                  <div className="product-image" key={name}>
                    <img src={`/images/home/products/${name}.png`} alt={name} />
                  </div>
                </Link>
              ))}
            </div>
          )}
          {(isMobile && !isLandscape) && (
            <Swiper
              slidesPerView={2}
              spaceBetween={12}
              centeredSlides={true}
              initialSlide={1}
            >
              {products.map(({ name }) => (
                <SwiperSlide key={name}>
                  <div className="product-image" >
                    <img
                      src={`/images/home/products/${name}.png`}
                      alt={name}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
