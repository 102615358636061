import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const models = ["network", "poker", "package", /*"club-app", "users"*/];

const BusinessModels = () => {
  const { t } = useTranslation();
  return (
    <div className={`business-models ${isMobile ? 'mobile' : ''}`}>
      <div className="container">
        <div className="business-models-inner">
          <h3>{t("businessModels")}</h3>
          <div className="models">
            {models.map((model, index) => {
              return (
                <div key={model} className={`model model-${index + 1}`}>
                  <div className="cliph-line" />
                  <div className="icon-texts-wrapper">
                    <div className="icon-wrapper">
                      <i className={`icon-${model}`} />
                    </div>
                    <div className="texts">
                      <h4>{t(`productsConfig.${model}.title`)}</h4>
                      <p>{t(`productsConfig.${model}.description`)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessModels;
