import { Link } from "react-router-dom";
import LogoImg from "../../assets/images/logo.png";
const Logo = () => {
  return (
    <Link to="/">
      <div className="logo">
        <img src={LogoImg} alt="logo" />
      </div>
    </Link>
  );
};

export default Logo;
