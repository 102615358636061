import { useRef } from "react";
import Platforms from "../../components/Platforms";
import { gamingPlatformConfig } from "../../components/Platforms/config";
import Welcome from "../../components/Welcome";

const GamingPlatform = () => {
  const platformNode = useRef();
  const scrollToRef = () => {
    platformNode.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Welcome onScrollRef={scrollToRef} bgcTransparent />
      {gamingPlatformConfig?.length > 0 && (
        <Platforms node={platformNode} data={gamingPlatformConfig} />
      )}
    </>
  );
};

export default GamingPlatform;
