export const pokerPlatformConfig = [
  {
    icon: "mobile-android",
    title: "mobile",
    decription: "Enjoy poker with native apps on your smartphone.",
    buttons: [
      { icon: 'apple', name: 'ios' },
      { icon: 'android', name: 'android', link: 'https://appho.st/d/PcwLjP4p' },
    ]
  },
  {
    icon: "globe",
    title: "web",
    decription: "Try HTML5 version without downloads.",
    buttons: [
      { name: 'openVersion', link: 'https://apps.pokerplaza.com/?skinId=3133730' },
      { name: 'openVersion', link: 'https://poker.gamedaddy.com/' },
    ]
  },
  {
    icon: "desktop",
    title: "desktop",
    decription: "Download Desktop version for Windows or for macOS.",
    buttons: [
      { icon: 'apple', name: 'mac'},
      { icon: 'windows',  name: 'windows', link: 'https://klasdlv2.draftplaza.com/getSkinDL.php?skinId=186' },
    ]
  },
];

export const clubGamesPlatformConfig = [
  {
    icon: "mobile-android",
    title: "mobile",
    decription: "Study 800 Numbers Still Popular With Advertisers",
    buttons: [
      { icon: 'apple', name: 'ios' },
      { icon: 'android', name: 'android', link: 'https://appho.st/d/afK7XVlb' },
    ]
  },
  {
    icon: "globe",
    title: "web",
    decription: "Study 800 Numbers Still Popular With Advertisers",
    buttons: [
      { name: 'open', link: 'https://clubdaddy.net' },
    ]
  },
  {
    icon: "desktop",
    title: "desktop",
    decription: "Study 800 Numbers Still Popular With Advertisers",
    buttons: [
      { icon: 'apple', name: 'mac'},
      { icon: 'windows',  name: 'windows' },
    ]
  },
];

export const gamingPlatformConfig = [
  {
    icon: "mobile-android",
    title: "mobile",
    decription: "Study 800 Numbers Still Popular With Advertisers",
    buttons: [
      { icon: 'apple', name: 'ios' },
      { icon: 'android', name: 'android', link: 'https://appho.st/d/YnIGQt9l' },
    ]
  },
  {
    icon: "globe",
    title: "web",
    decription: "Study 800 Numbers Still Popular With Advertisers",
    buttons: [
      { name: 'openVersion', link: 'https://skindemo.gamedaddy.com/' },
      { name: 'openVersion', link: 'https://coinbar69.com/' },
    ]
  },
];
