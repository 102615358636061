import { Redirect, Route, Switch } from "react-router-dom";
import { routes } from "../../constants/routes";
import ScrollToTop from "../ScrollToTop";

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        {routes.map((route) => {
          const Component = route?.component;
          return Component ? (
            <Route exact path={route.path} key={route.path}>
              <Component />
            </Route>
          ) : (
            <Redirect key={route.path} to="/" />
          );
        })}
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
