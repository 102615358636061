import { useTranslation } from "react-i18next";

const GameBox = () => {
  const { t } = useTranslation();
  return (
    <div className="game-box">
      <div className="game-box__container">
        <div style={{ backgroundImage: 'url("/images/gameBox/block-backgroud.png")' }} className="game-box__backgorund-block game-box__backgorund-block--first">
          <div className="game-box__backgorund-block-texts-wrapper">
            <span className="game-box__backgorund-block-text-title">{t('gameBoxPage.backgroundBlock1.title')}</span>
            <span className="game-box__backgorund-block-text-description">{t('gameBoxPage.backgroundBlock1.description')}</span>
          </div>
          <div className="game-box__images-wrapper">
            <img className="game-box__arrow-image" src="/images/gameBox/block1arrow.png" alt="arrow" />
            <img className="game-box__image-1" src="/images/gameBox/block1phone1.png" alt="phone image" />
            <img className="game-box__image-2" src="/images/gameBox/block1phone2.png" alt="phone image" />
          </div>
        </div>
        {/*  */}
        <div className="game-box__block">
          <span className="game-box__block-title">{t('gameBoxPage.block1.title')}</span>
          <span className="game-box__block-subtitle">{t('gameBoxPage.block1.subtitle')}</span>
          <img className="game-box__block-phones" src="/images/gameBox/phones-group1.png" alt="phones" />
        </div>
        {/*  */}
        <div style={{ backgroundImage: 'url("/images/gameBox/block-backgroud.png")' }} className="game-box__backgorund-block game-box__backgorund-block--last">
          <div className="game-box__backgorund-block-texts-wrapper">
            <span className="game-box__backgorund-block-text-title">{t('gameBoxPage.backgroundBlock2.title')}</span>
            <span className="game-box__backgorund-block-text-subtitle">{t('gameBoxPage.backgroundBlock2.subtitle')}</span>
            <span className="game-box__backgorund-block-text-description">{t('gameBoxPage.backgroundBlock2.description')}</span>
          </div>
          <div className="game-box__images-wrapper">
            <img className="game-box__image-1" src="/images/gameBox/block2phone1.png" alt="phone image" />
            <img className="game-box__arrow-image" src="/images/gameBox/block2arrow.png" alt="arrow" />
            <img className="game-box__image-2" src="/images/gameBox/missions.png" alt="phone image" />
          </div>
        </div>
        <div className="game-box__block">
          <span className="game-box__block-title">{t('gameBoxPage.block2.title')}</span>
          <img className="game-box__block-phones" src="/images/gameBox/phones-group2.png" alt="phones" />
        </div>
      </div>
    </div>    
  );
};

export default GameBox;
