import { useRef } from "react";
import Features from "../../components/Features";
import Platforms from "../../components/Platforms";
import { pokerPlatformConfig } from "../../components/Platforms/config";
import Welcome from "../../components/Welcome";

const Poker = () => {
  const platformNode = useRef();
  const scrollToRef = () => {
    platformNode.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Welcome onScrollRef={scrollToRef} />
      <Features />
      {pokerPlatformConfig?.length > 0 && (
        <Platforms data={pokerPlatformConfig} node={platformNode} />
      )}
    </>
  );
};

export default Poker;
