import { useTranslation } from "react-i18next";

const allInInsturanceInfo = [
  { label: "timeClock", value: "timeClockDescription" },
  { label: "pot", value: "potDescription" },
  { label: "myBet", value: "myBetDescription" },
  { label: "favoritesHoleCards", value: "favoritesHoleCardsDescription" },
  { label: "communityCards", value: "communityCardsDescription" },
  { label: "underdogsHoleCards", value: "underdogsHoleCardsDescription" },
  { label: "FavoritesHandanking", value: "FavoritesHandankingDescription" },
  { label: "UnderdogsHandRanking", value: "UnderdogsHandRankingDescription" },
  { label: "outs", value: "outsDescription" },
  { label: "outsCount", value: "outsCountDescription" },
  { label: "odds", value: "oddsDescription" },
  { label: "min", value: "minDescription" },
  { label: "breakEven", value: "breakEvenDescription" },
  { label: "max", value: "maxDescription" },
  { label: "paySlider", value: "paySliderDescription" },
  { label: "pay", value: "payDescription" },
  { label: "save", value: "saveDescription" },
  { label: "ok", value: "okDescription" },
  { label: "cancel", value: "cancelDescription" },
];

const Insurance = () => {
  const { t } = useTranslation();
  return (
    <div className="insurance">
      <div className="container">
        <div className="insurance-inner">
          <div className="row">
            <div className="img-title-wrapper">
              <img
                src="/images/insurance/insurance-title.png"
                alt="title image"
              />
            </div>
          </div>
          <div className="row">
            <h3>{t("allInInsurance")}</h3>
            <p>{t("allInInsuranceDescription")}</p>
          </div>
          <div className="row">
            <h4>{t("whatIsIt")}</h4>
            <p>{t("whatIsItDescriptionInsurance")}</p>
          </div>
          <div className="row">
            <h4>{t("howItWorks")}</h4>
            <p>{t("howItWorksDescriptionInsurance")}</p>
          </div>
          <div className="row">
            <p>{t("shortDescriptionOfAllInFunctionalities")}</p>
          </div>
          <div className="row">
            <div className="left-right-wrapper">
              <div className="left">
                <img
                  src="/images/insurance/insurance-desc.png"
                  alt="insurance desc"
                />
              </div>
              <div className="right">
                {allInInsturanceInfo.map(({ label, value }, index) => (
                  <div className="insurance-info-wrapper" key={label}>
                    <span className="index">{index + 1}</span>
                    <span className="label">{t(label)}</span>
                    <span>-</span>
                    <span className="value">{t(value)}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <p className="center">{t("respectiveCheckboxDescription")}</p>
          </div>
          <div className="row">
            <div className="insurance-checkbox-img-wrapper">
              <img
                src="/images/insurance/insurance-checkbox.png"
                alt="insurance checkbox"
              />
            </div>
          </div>
          <div className="row">
            <div className="table-wrapper">
              <div className="row">
                <h3>{t("insuranceOdds")}</h3>
                <p>{t("insuranceOddsDescription")}</p>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>{t("outs")}</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th className="red">15</th>
                    <th className="red">16</th>
                    <th className="red">17</th>
                    <th className="red">18</th>
                    <th className="red">19</th>
                    <th className="red">20+</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bold">{t("holdemOdds")}</td>
                    <td>31</td>
                    <td>16</td>
                    <td>10</td>
                    <td>8</td>
                    <td>6</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3.5</td>
                    <td>3</td>
                    <td>2.5</td>
                    <td>2.3</td>
                    <td>2</td>
                    <td>1.8</td>
                    <td>1.6</td>
                    <td className="red">1.4</td>
                    <td className="red">1.3</td>
                    <td className="red">1.2</td>
                    <td className="red">1.1</td>
                    <td className="red">1</td>
                    <td className="red">0.8</td>
                  </tr>
                  <tr>
                    <td className="bold">{t("omahaOdds")}</td>
                    <td>24</td>
                    <td>12</td>
                    <td>8</td>
                    <td>6</td>
                    <td>4.5</td>
                    <td>4</td>
                    <td>3.2</td>
                    <td>2.7</td>
                    <td>2.3</td>
                    <td>2</td>
                    <td>1.7</td>
                    <td>1.5</td>
                    <td>1.4</td>
                    <td>1.2</td>
                    <td className="red">1.1</td>
                    <td className="red">1</td>
                    <td className="red">0.9</td>
                    <td className="red">0.8</td>
                    <td className="red">0.8</td>
                    <td className="red">0.7</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="terms-conditions-wrapper">
              <h3>{t("termsConditions")}</h3>
              <ul>
                {Array.from({ length: 14 }, (_, k) => k).map((index) => {
                  return <li key={index}>{t(`insuranceTermsConditionsConfig.${index}`)}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
