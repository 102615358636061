import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      {t('footerText')}
    </div>
  );
};

export default Footer;
